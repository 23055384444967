.video_list {
    display: flex;

    flex-direction: row;
    flex-flow: row;
    flex-wrap: nowrap;

    overflow: scroll;
}
.video_list>div{
    margin: 10px;
}

.video_title{
    border-bottom: lightblue 1px solid;
}
.video_img {
    margin: 5px;

    width: 150px;
    height: 150px;
    overflow: clip;
}

.video_text {
    text-align: center;
}

.video_block {
    box-shadow: silver 5px 5px;
    border: lightsteelblue 1px solid;

    display: flex;
    flex-direction: column;
}
